import React from 'react';
import { DefaultLayoutProps } from '../../../../_types/DefaultLayout';
import { BoxParams } from '../../../BoxParams';

export function DefaultLayout({
  eventSelected,
  titleBoxRight,
  rightParameters,
  onChangeViewMode,
}: DefaultLayoutProps): JSX.Element {
  return (
    <>
      <BoxParams
        title="Parâmetros recebidos"
        parameters={eventSelected?.dataParams}
        className="left__parameters"
      />
      <BoxParams
        title={titleBoxRight}
        parameters={rightParameters}
        className="right__parameters"
        onAction={onChangeViewMode}
        titleAction="Ver completo"
      />
    </>
  );
}
