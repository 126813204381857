import React from 'react';
import { useNavigate } from 'react-router-dom';
import type {
  EventsInformations,
  GetDataEventSendProps,
  GetInformationFromSelectedEventsReturn,
  UseResultSearchReturn,
  GetDataJSONReturn,
} from '../_types/ResultSearch';
import { useFormStatusContext } from '../components/Contexts/FormStatus';
import type { DataEventDetailsSelected } from '../_types/DetailsEventsModal';

export function useResultSearch(): UseResultSearchReturn {
  const navigate = useNavigate();
  const { stateFormStatus } = useFormStatusContext();

  const platform = stateFormStatus?.platform?.toUpperCase();

  const titles = {
    loading: 'Analizando dados',
    success: 'Sucesso',
    error: 'Erro',
  };

  const [infoLoading, setInfoLoading] = React.useState({
    isLoading: true,
    title: 'Analisando informações',
  });

  const [dataAlert, setDataAlert] = React.useState({
    isOpen: false,
    message: '',
  });

  const [styleHeader, setStyleHeader] = React.useState<
    'loading' | 'success' | 'error'
  >('loading');

  const [isShowDetails, setIsShowDetails] = React.useState(false);

  const [eventsInformations, setEventsInformations] =
    React.useState<EventsInformations>({
      amountEventsSelectedFound: 0,
      amountEventsSelectedNotFound: 0,
      amountEventsDisapproved: 0,
      amountTraceIdInformed: 0,
      eventsSelectedNotFound: [],
      organizedInformation: [],
    });

  const [eventSelected, setEventSelected] =
    React.useState<DataEventDetailsSelected>();

  const eventsSelected = stateFormStatus?.events ?? [];
  const isUserSeletedEvents = eventsSelected.length > 0;

  function onHandleSelectedEventDetails(event: DataEventDetailsSelected): void {
    if (!isShowDetails) setIsShowDetails(true);
    setEventSelected(event);
  }

  function resetLoadiong(): void {
    setInfoLoading({
      isLoading: false,
      title: 'Analisando informações',
    });
  }

  function getDataEventSend({
    nameEvent,
    eventsSendFromTraceId,
  }: GetDataEventSendProps): any {
    const eventFound =
      eventsSendFromTraceId.find(item => {
        const nameWithoutEnvironmentFlag = item?.name
          ?.replace(/_?(stg|qa)_?/gi, '')
          .trim();
        return nameEvent === nameWithoutEnvironmentFlag;
      }) ?? null;

    return eventFound;
  }

  function getInformationFromSelectedEvents(
    data: any[],
  ): GetInformationFromSelectedEventsReturn {
    let amountEventsSelectedFound = 0;

    data.forEach(item => {
      const isEventSelectedFound = eventsSelected.some(
        eventEntry => eventEntry === item.fullData.dataParams.name,
      );
      if (isEventSelectedFound) {
        amountEventsSelectedFound++;
      }
    });

    const eventsSelectedNotFound = eventsSelected.map(eventEntry => {
      const eventNotFound = !data.some(
        eventInformation =>
          eventInformation?.fullData.dataParams.name === eventEntry,
      );
      return {
        name: eventEntry,
        status: eventNotFound ? 'not_found' : 'found',
      };
    });

    return {
      amountEventsSelectedFound,
      eventsSelectedNotFound,
    };
  }

  function getDataJSON(value: any): GetDataJSONReturn {
    return value;
  }

  function getOrganizedInformation(): void {
    try {
      const isValidData = stateFormStatus !== null;

      if (!isValidData) {
        setDataAlert({ isOpen: true, message: 'Sem dados para analisar' });
        return;
      }

      const flag = stateFormStatus?.flag?.toUpperCase();
      const organizedInformation: any[] = [];

      let amountEventsDisapproved = 0;

      const result = getDataJSON(JSON.parse(stateFormStatus?.input)) as Array<{
        params: {
          timestamp_micros: number;
          events: Array<{
            name: string;
          }>;
        };
        brand: string;
        sent: any;
        status: string;
        origin: string;
        invalidData: any;
        traceId: string;
      }>;

      result
        .sort(
          (prev, next) =>
            next?.params?.timestamp_micros - prev.params.timestamp_micros,
        )
        .forEach(itemResult => {
          const isDifferentFlag = itemResult?.brand !== flag;
          const isDisapproveByMS = itemResult?.status !== 'DONE';
          const isDifferentPlatform = itemResult?.origin !== platform;
          const isDisapproveEvents =
            isDisapproveByMS || isDifferentFlag || isDifferentPlatform;
          const isApprovedEvent = !isDisapproveEvents;

          itemResult?.params?.events.forEach(itemEvent => {
            const invalidDataFrontRules = [];
            let statusFrontTagify: 'success' | 'disapproved' = 'success';
            let invalidData = [];
            let dataSend = null;

            if (isApprovedEvent) {
              dataSend = getDataEventSend({
                nameEvent: itemEvent?.name,
                eventsSendFromTraceId: itemResult?.sent?.events ?? [],
              });
            }

            if (isDifferentFlag) {
              invalidDataFrontRules.push({
                flagRule: `A bandeira do evento, ${itemResult.brand}, é diferente da informada ${flag}`,
              });
            }

            if (isDifferentPlatform) {
              invalidDataFrontRules.push({
                platformRule: `A plataforma do evento, ${itemResult.origin}, é diferente da informada ${platform}`,
              });
            }

            if (isDisapproveEvents) {
              amountEventsDisapproved++;
              statusFrontTagify = 'disapproved';
            }

            if (isDisapproveByMS) {
              invalidData = itemResult.invalidData;
            }

            organizedInformation.push({
              fullData: {
                statusFrontTagify,
                dataParams: itemEvent,
                dataSend,
                invalidDataFrontRules,
                invalidData,
                traceId: itemResult?.traceId ?? '',
                statusMS: itemResult?.status,
                fullProcessEvent: itemResult,
              },
            });
          });
        });

      let fullInformations: EventsInformations = {
        amountEventsSend:
          organizedInformation?.filter(
            item => item?.fullData?.dataSend !== null,
          )?.length ?? 0,
        amountEventsDisapproved,
        amountTraceIdInformed: stateFormStatus.amountTraceIdInformed,
        organizedInformation,
      };
      if (isUserSeletedEvents) {
        const { amountEventsSelectedFound, eventsSelectedNotFound } =
          getInformationFromSelectedEvents(organizedInformation);

        fullInformations = {
          ...fullInformations,
          amountEventsSelectedFound,
          amountEventsSelectedNotFound: eventsSelectedNotFound.filter(
            item => item.status === 'not_found',
          ).length,
          eventsSelectedNotFound,
        };
      }

      const isExistEventsDisapproved =
        fullInformations.amountEventsDisapproved > 0;

      setStyleHeader(isExistEventsDisapproved ? 'error' : 'success');

      setEventsInformations(fullInformations);
    } catch {
      setDataAlert({ isOpen: true, message: 'Formato de dados inválido' });
    } finally {
      resetLoadiong();
    }
  }

  React.useEffect(() => {
    setTimeout(() => {
      getOrganizedInformation();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAlert]);

  React.useEffect(() => {
    const handleBeforeUnload = (event: any): void => {
      if (!dataAlert.isOpen) {
        event.preventDefault();
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dataAlert.isOpen]);

  return {
    onCloseAlert: () => navigate('/tagify'),
    onCloseDetailsEvents: () => setIsShowDetails(false),
    onHandleSelectedEventDetails,
    dataAlert,
    eventsInformations,
    infoLoading,
    shouldRenderResultsOfSelectedEvents: isUserSeletedEvents,
    styleHeader,
    titlePage: titles[styleHeader],
    isShowDetails,
    eventSelected,
  };
}
