import React, { useState } from 'react';
import './style.css';
import { Sidebar } from './Partials/Sidebar';
import { Header } from './Partials/Header';
import { type DetailsEventsModalProps } from '../../_types/DetailsEventsModal';
import { BoxParams } from '../BoxParams';
import { createPortal } from 'react-dom';
import { Snackbar } from '@material-ui/core';
import { DefaultLayout } from './Partials/DefaultLayout';
import { ShouldRender } from '../ShouldRender';

export function DetailsEventsModal({
  onClose,
  isShowDetails,
  data,
  eventSelected,
  onHandleSelectedEventDetails,
}: DetailsEventsModalProps): JSX.Element {
  const styleContainer = isShowDetails ? 'container__open' : 'container__close';
  const isSuccessEvent = eventSelected?.statusFrontTagify === 'success';
  const titleBoxRight = isSuccessEvent
    ? 'Parâmetros enviados'
    : 'Lista de erros';
  const rightParameters = isSuccessEvent
    ? eventSelected?.dataSend
    : {
        invalidData: eventSelected?.invalidData,
        invalidDataFrontRules: eventSelected?.invalidDataFrontRules,
      };
  const [isOpenToast, setIsOpenToast] = useState(false);
  const [viewMode, setViewMode] = useState<'default' | 'complete'>('default');

  function onCopyDataClipboard() {
    const isDefaultLayout = viewMode === 'default';
    const content = isDefaultLayout
      ? rightParameters
      : eventSelected?.fullProcessEvent;

    navigator.clipboard
      .writeText(JSON.stringify(content, null, 2))
      .then(() => {
        setIsOpenToast(true);
      })
      .catch(() => {});
  }

  function onChangeViewMode(value: 'default' | 'complete') {
    setViewMode(value);
  }

  function onCloseModal() {
    onClose();
    if (viewMode === 'complete') setViewMode('default');
  }

  return createPortal(
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isOpenToast}
        onClose={() => setIsOpenToast(false)}
        message="copiado para área de transferência"
        key="#"
      />
      <section className={`container__details ${styleContainer}`}>
        <div className="details__box">
          <Sidebar
            data={data}
            traceIdSelected={eventSelected?.traceId ?? ''}
            onHandleSelectedEventDetails={onHandleSelectedEventDetails}
          />
          <Header
            statusFront={eventSelected?.statusFrontTagify ?? ''}
            statusMS={eventSelected?.statusMS ?? ''}
            nameEvent={eventSelected?.dataParams?.name ?? ''}
            traceId={eventSelected?.traceId ?? ''}
            onClose={onCloseModal}
            isSuccessEvent={isSuccessEvent}
            onCopyDataClipboard={onCopyDataClipboard}
          />
          <ShouldRender condition={viewMode === 'default'}>
            <DefaultLayout
              eventSelected={eventSelected}
              titleBoxRight={titleBoxRight}
              rightParameters={rightParameters}
              onChangeViewMode={() => onChangeViewMode('complete')}
            />
          </ShouldRender>
          <ShouldRender condition={viewMode === 'complete'}>
            <BoxParams
              title="Parâmetros processados"
              parameters={eventSelected?.fullProcessEvent}
              className="full__parameters"
              onAction={() => onChangeViewMode('default')}
              titleAction="Voltar"
            />
          </ShouldRender>
        </div>
      </section>
    </>,
    document.body,
  );
}
