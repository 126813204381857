import { useFormStatusContext } from '../components/Contexts/FormStatus';

export function useSaveFile() {
  const { stateFormStatus } = useFormStatusContext();

  function createBlob() {
    const jsonObject = JSON.parse(stateFormStatus?.input);

    const jsonString = JSON.stringify(jsonObject, null, 2);

    const blob = new Blob([jsonString], { type: 'application/json' });

    return blob;
  }

  function getNameFile() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    const hours = String(today.getHours()).padStart(2, '0');
    const minutes = String(today.getMinutes()).padStart(2, '0');

    return `tagify-${day}-${month}-${year}-${hours}-${minutes}`;
  }

  function saveJsonToFile() {
    try {
      const blob = createBlob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      const fileName = getNameFile();

      a.href = url;
      a.download = `${fileName}.json`;

      document.body.appendChild(a);

      a.click();

      document.body.removeChild(a);

      URL.revokeObjectURL(url);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  return { saveJsonToFile };
}
