import React from 'react';
import './style.css';
import {
  AlertModal,
  CardEvent,
  CardNumber,
  DetailsEventsModal,
  FullLoading,
  LabelEvent,
} from '../components';
import { useResultSearch } from './useResultSearch';
import { useFormStatusContext } from '../components/Contexts/FormStatus';
import { Link } from 'react-router-dom';
import { useSaveFile } from './useSaveFile';

export function ResultSearch(): JSX.Element {
  const {
    onCloseAlert,
    onCloseDetailsEvents,
    onHandleSelectedEventDetails,
    dataAlert,
    eventsInformations,
    shouldRenderResultsOfSelectedEvents,
    infoLoading,
    styleHeader,
    titlePage,
    isShowDetails,
    eventSelected,
  } = useResultSearch();
  const { stateFormStatus } = useFormStatusContext();
  const { saveJsonToFile } = useSaveFile();
  const headerStyle = `header__${styleHeader}`;

  return (
    <React.Fragment>
      <DetailsEventsModal
        isShowDetails={isShowDetails}
        data={eventsInformations.organizedInformation}
        eventSelected={eventSelected}
        onClose={onCloseDetailsEvents}
        onHandleSelectedEventDetails={onHandleSelectedEventDetails}
      />
      <AlertModal
        isOpen={dataAlert.isOpen}
        title={dataAlert.message}
        onPress={onCloseAlert}
      />
      <FullLoading {...infoLoading} />

      <section className="container__result">
        <header className={`result__header ${headerStyle}`}>
          <div className="header__safe">
            <h1 className="result__title">{titlePage}</h1>
            <hr className="result__line" />
          </div>
        </header>
        <section className="result__cards">
          <div className="cards__divider">
            <p className="divider__title">Dados informados</p>
          </div>
          <div className="cards__input">
            <p>
              <strong>Bandeira:</strong> {stateFormStatus?.flag?.toUpperCase()}
            </p>
            <p>
              <strong>Plataforma:</strong>{' '}
              {stateFormStatus?.platform?.toUpperCase()}
            </p>
            <p>
              <strong>Ambiente:</strong>{' '}
              {stateFormStatus?.environment?.toUpperCase()}
            </p>
          </div>
          <div className="cards__divider">
            {/* <button onClick={mudar}>ATUALIZAR</button> */}
            <p className="divider__title">Dados gerais</p>
            <div className="divider__options">
              <button
                type="button"
                className="option__button"
                onClick={saveJsonToFile}
              >
                Salvar logs
              </button>

              <Link to="/tagify">
                <button
                  type="button"
                  className="option__button option__button--search"
                >
                  Nova busca
                </button>
              </Link>
            </div>
          </div>
          <div className="cards__items">
            <CardNumber
              label="TraceID's informados"
              status="informed"
              value={eventsInformations.amountTraceIdInformed}
            />

            {shouldRenderResultsOfSelectedEvents ? (
              <CardNumber
                label="Eventos encontrados"
                status="found"
                value={eventsInformations.amountEventsSelectedFound ?? 0}
              />
            ) : null}

            <CardNumber
              label="Eventos enviados"
              status="sent"
              value={eventsInformations.amountEventsSend ?? 0}
            />

            <CardNumber
              label="Eventos reprovados"
              status="disapproved"
              value={eventsInformations.amountEventsDisapproved}
            />

            {shouldRenderResultsOfSelectedEvents ? (
              <CardNumber
                label="Eventos não encontrados"
                status="notFound"
                value={eventsInformations.amountEventsSelectedNotFound ?? 0}
              />
            ) : null}
          </div>

          {shouldRenderResultsOfSelectedEvents ? (
            <React.Fragment>
              <div className="cards__divider">
                <p className="divider__title">Eventos selecionados</p>
              </div>
              <div className="cards__search">
                {(eventsInformations?.eventsSelectedNotFound ?? []).map(
                  (item, index) => (
                    <LabelEvent
                      key={index}
                      name={item?.name}
                      isDisabled
                      variant={item?.status}
                    />
                  ),
                )}
              </div>
              <div className="cards__legend">
                <p className="legend__found">Encontrados</p>
                <p className="legend__not_found">Não encontrados</p>
              </div>
            </React.Fragment>
          ) : null}

          {/* <div className="cards__divider">
            <p className="divider__title">Detalhes da busca</p>
            <button type="button" className="divider__button">
              Imprimir
            </button>
          </div> */}
          <div className="cards__events">
            {eventsInformations.organizedInformation.map((data, index) => (
              <CardEvent
                key={index}
                name={data?.fullData?.dataParams?.name}
                status={data?.fullData?.statusFrontTagify}
                onClick={() => onHandleSelectedEventDetails(data?.fullData)}
              />
            ))}
          </div>
        </section>
      </section>
    </React.Fragment>
  );
}
