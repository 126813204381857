import React from 'react';

import './style.css';
import type { BoxParamsProps } from '../../_types/BoxParams';
import { ShouldRender } from '../ShouldRender';

export function BoxParams({
  title,
  parameters,
  className,
  titleAction,
  onAction,
}: BoxParamsProps): JSX.Element {
  const shouldRenderAction = !!onAction;

  return (
    <section className={`container__params ${className}`}>
      <div className="param__header">
        <p className="param__title">{title}</p>
        <ShouldRender condition={shouldRenderAction}>
          <button type="button" onClick={onAction} className="param__button">
            {titleAction}
          </button>
        </ShouldRender>
      </div>

      <div className="param__box">
        <div className="box__scroll">
          <pre>{JSON.stringify(parameters, null, 2)}</pre>
        </div>
      </div>
    </section>
  );
}
